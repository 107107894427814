import React from "react";
import {accountsMap} from "@/pages/account/helper";

interface Props {
    title?: string
    active: any
    tabs?: any[]
}

const CommonTabs = (props: Props) => {
    const {title = '', active, tabs = accountsMap} = props

    return (
        <div className='common-tabs-wrapper'>
            <div className="tabs-current">{title}</div>
            <div className="common-tabs">
                {tabs.map((item: any) =>
                    <div className={active === item.value && 'common-tab-active' || 'common-tab'} key={item.value}
                         onClick={() => location.href = item.path}>
                        <div className='flex align-items-center' style={{whiteSpace: 'nowrap'}}>
                            <div className={active === item.value && 'tabs-dot-active' || 'tabs-dot'}/>
                            {item.label}
                        </div>
                        {active === item.value && <img src="/images/arrow-right.png" className='icon16' alt=""/> || ''}
                    </div>
                )}
            </div>
        </div>
    )
}
export default CommonTabs