
export const accountsMap: any[] = [
    {
        label: '商品管理',
        value: 1,
        path: '/Account/Goods.html'
    },
    {
        label: '个人信息',
        value: 2,
        path: '/Account/Info.html'
    },
    {
        label: '装箱方案',
        value: 3,
        path: '/Account/Pack.html'
    },
    // {
    //     label: '地址管理',
    //     value: 3
    // }
]